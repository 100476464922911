$theme-bg:   #3a3a3a ;  
$login-bg: #2d2d2d;
$theme-bg2:  #444444;  
$white-text: #ffffff;
$black-text:#000000;
$text-color:  #aaaaaa;  

$text-color2:  #cccccc ;  
$theme-font: 'Roboto';
$label-color: #979797;
$btn-bg: #f37b54;
$link-text:#f4f3f0;
$grey-bg :#666666;
$grey-bg2 :#606060;
$grey-bg3: #505050;
$grey-text :  #777777;  
$disabled-text: #979797;  
$btn-box:#505050;
$selected-bg:#faa68b;
$text-color3:   #606060;  
$text-color4:  #cccccc;  
$text-color5:   #aaaaaa;  
$btn-bg2:#505050;


 


$blue-select-bg:#15b1e9;
$icon-button:#338bc0;
$fade-bg:#eeeeee;
