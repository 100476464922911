/* You can add global styles to this file, and also import other style files */
//@import './assets/sass/variables.scss';
@import './assets/fonts/roboto/roboto.scss';
@import './assets/sass/mixins.scss';
@import '~ngx-org-chart/_theming.scss';
@import "./assets/sass/material-components/mat-input";


// @include()
@include mat-input-feild-scss;


/* root strts */
:root {
  --theme-bg: #3a3a3a;
  --theme-bg2:#444444;
  --login-bg: #2d2d2d;
  --link-text: #f4f3f0;
  --link-text2: #f4f3f0;
  --text-color: #aaaaaa;
  --text-color2:  #cccccc;
  --text-color3:   #606060;
  --text-color4:  #aaaaaa;
  --text-color5:#606060;
  --label-color: #979797;
  --btn-bg: #f37b54;
  --grey-bg :#666666;
  --grey-bg2 :#606060;
  --grey-bg3: #505050;
  --grey-text :  #777777;
  --disabled-text: #979797;
  --selected-bg:#faa68b;
  --disabled-text2: #777777;
  --btn-bg2:#505050;
  --btn-bg3:#606060;
  --btn-bg4:#eeeeee;
  --box-text: #aaaaaa;
  --service-seleted-bg: #2d2d2d;
  --table-even-bg:#505050;
  --edit-cell-bg:#f4f3f0;
  --edit-cell:#444444;
  --selected-row:#666666;
  --sidebar-firsttime-text: #606060;
  --input-field-bg: #606060;
  --placeholder-color: #aaaaaa;
  --arrow-color: #cccccc;
  --chk-label: #ffffff;
  --block-bg : #505050;
  --box-bg : #606060;
  --text-grey: #444444;
  --chk-bg: #f4f3f0;
  --block-color: #cccccc;
  --error-text: #777777;
  --arrow-color: #cccccc;
  --chk-label: #ffffff;
  --block-bg : #505050;
  --table-odd-bg:#444444;  
  --warning-bg:#444444;
  --toggle-active-label:#ffffff;
  --disable-btn:#444444;
  --webkit-scroll:#666666;
  --scroll-thumb-color:#999999;
  --click-block:#2d2d2d;
  --highlight-radio:#cccccc;
  --inner-radio:#cccccc;
  --invite-accept:#3dc97c;
  --pagination-active:#777;
  --disable-tab:#979797;
  --chkbox-bg:#cccccc;
  --disabled-key-bg:#505050;
  --disabled-key-color:#777777;
  --keys-available-label:#3dc97c;
  --old-shortcut-bg:#505050;
  --pedal-bg:#3a3a3a;
  --pedal-label:#e97575;
  --pedal-circle:#444444;
  --pedal-title-text: #777777;
  --speaker-section-bg: #2d2d2d;
  --icon-color: #777777;
  --color-picker-border: #cccccc;
  --tat-btn-bg:#606060;
  --job-box:#2d2d2d;
--text-only:#9b9b9b;
--tooltip-bg:#606060;
--invite-accept:#3dc97c;
--Deluxe-Media:#dc905e;
--National-Geographic-Channel:#ffefc6;
--users:#15b1e9;
--projects:#3dc97c;
--search-field-bg: #444444;
--workflow:#888888;
--level-number:#606060;
--text-color6:#cccccc;
--words-edited:#3dc97c;
--words-deleted: #eebb3e;
--slider-label:#faa68b;
--validation-color:#ffe000;
--node-label: #aaaaaa;
--icon-clickstate: #555555;
--icon-clickstate2: #555555;
--active-page: #777777;
--new-pedal-label: #eebb3e;
--new-pedals-bg: #3a3a3a;
--new-pedal-circle: #444444;
--click--option:#535353;
--input--line: #888888;
--disabled-text3: #979797;
--suffix-color: #cccccc;
--number-color: #cccccc;
--file-loading: #338bc0;
--loading-buffer: #3a3a3a;
--pointer-color-active: #3a3a3a;
--transfer-icon: #999999;
--highlight-color: #ffffff;
--backdrop-bg: #2d2d2d;
--num-range: #606060;
--opp-selected-bg: #ffc0a5;
--scroll-thumb-color-2: #999999;
--custom-tooltip-bg: #272727;
--custom-tooltip-color: #ccc;
}

[data-theme="light"] {
  --theme-bg: #ffffff;
  --theme-bg2:#eeeeee;
  --login-bg: #666666;
  --link-text: #2d2d2d;
  --link-text2: #444444;
  --text-color: #444444;
  --text-color2:  #444444;
  --text-color3:   #eeeeee;
  --text-color4:  #606060;
  --text-color5:#444444;
  --label-color: #606060;
  --btn-bg: #f37b54;
  --grey-bg :#dddddd;
  --grey-bg2 :#cccccc;
  --grey-bg3: #dddddd;
  --grey-text :  #aaaaaa;
  --disabled-text: #aaaaaa;
  --selected-bg:#ffc0a5;
  --disabled-text2: #aaaaaa;
  --btn-bg2:#e0e0e0;
  --btn-bg3:#dddddd;
  --btn-bg4:#dddddd;
  --box-text: #777777;
  --service-seleted-bg: #dddddd;
  --table-even-bg:#f8f8f8;
  --edit-cell-bg:#606060;
  --edit-cell:#f4f3f0;
  --selected-row:#cccccc;
  --sidebar-firsttime-text: #aaaaaa;
  --input-field-bg: #f8f8f8;
  --placeholder-color: #888888;
  --arrow-color: #606060;
  --chk-label: #2d2d2d;
  --block-bg : #eeeeee;
  --box-bg : #f4f3f0;
  --text-grey: #2d2d2d;
  --chk-bg: #ffffff;
  --block-color: #3a3a3a;
  --error-text: #606060;
  --arrow-color: #606060;
  --chk-label: #2d2d2d;
  --block-bg : #eeeeee;
  --table-odd-bg:#eeeeee;  
  --warning-bg:#ffffff;
  --click-block:#f8f8f8;
  --toggle-active-label:#444444;
  --disable-btn:#e0e0e0;
  --webkit-scroll:#e0e0e0;
  --scroll-thumb-color:#aaaaaa;
  --highlight-radio:#f8f8f8;
  --inner-radio:#f8f8f8;
   --invite-accept:#3dc97c;
   --pagination-active:#eeeeee;
   --disable-tab:#888;
   --chkbox-bg:#f8f8f8;
   --disabled-key-bg:#aaaaaa;
   --disabled-key-color:#cccccc;
   --keys-available-label:#06a38a;
   --old-shortcut-bg:#cccccc;
   --pedal-bg:#606060;
   --pedal-label:#eebb3e;
   --pedal-circle:#777777;
   --pedal-title-text: #888888;
   --speaker-section-bg: #ffffff;
   --icon-color: #979797;
   --color-picker-border: #979797;
   --tat-btn-bg:#e0e0e0;
   --job-box:   #eeeeee;
   --text-only:   #888888;
   --tooltip-bg:#ffffff;
   --invite-accept:#06a38a;
   --Deluxe-Media:#a66599;
   --National-Geographic-Channel:#3c87a8;
   --users:#338bc0;
   --projects:#06a38a;
   --search-field-bg:#f8f8f8;
   --workflow:#979797;
   --level-number:#979797;
   --text-color6:#2d2d2d;
   --words-edited:#6a00d2;
   --words-deleted: #c70303;
   --slider-label:#f37b54;
   --validation-color:#ec122c;
   --node-label: #979797;
   --icon-clickstate: #e0e0e0;
   --icon-clickstate2: #cccccc;
   --active-page: #eeeeee;
   --new-pedal-label: #ec122c;
   --new-pedals-bg: #eeeeee;
   --new-pedal-circle: #dddddd;
   --click--option:#cecece;
   --input--line: #606060;
   --disabled-text3: #888888;
   --suffix-color: #888888;
   --number-color: #eeeeee;
   --file-loading: #15b1e9;
   --loading-buffer: #cccccc;
   --pointer-color-active: #ffffff;
   --transfer-icon: #888888;
   --highlight-color: #888888;
   --backdrop-bg: #606060;
   --num-range: #ffc0a5;
   --opp-selected-bg: #f37b54;
   --scroll-thumb-color-2: #aaaaaa;
   --custom-tooltip-bg: #999;
   --custom-tooltip-color: #fff;
}

/* ends here */
html, body {
   height: 100%;
   overflow-x: auto;
}
html.cdk-global-scrollblock {
  top: unset !important;
  overflow-y: hidden !important;
}
body { margin: 0; font-family: 'Roboto';background-color:var(--theme-bg);overflow-y:auto; }

* {
  font-family: 'Roboto';
}
.error-list {
  color: var(--validation-color);
  font-size: 13px;
}
.mat-error {
  color: var(--validation-color) !important;
}
// .mat-raised-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay
.trance-image {
  img {
    max-width: 538px;
  }
}
@media (min-width: 1919px) {
  .login .login-frame {
    .Version-label {
      margin-left: 692px !important;
    }
  }
  .trance-image {
    img {
      max-width: 800px;
    }
  }
}
.trance-icon {
  img.Trance {
    // max-width: 132px;
  }
}
button {
  outline: none;
  border: none;
  color: $white-text;
  &.mat-raised-button {
    &:hover {
      &:not(.mat-button-disabled) {
        .mat-button-focus-overlay {
          opacity: .04;
        }
      }
    }
  }
}
/*chrome trackbar css*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: var(--scroll-thumb-color-2);
}

::-webkit-scrollbar-track {
  background: var(--webkit-scroll);
}

/*mozilla trackbar css*/
::-moz-scrollbar {
  width: 7px;
  height: 7px;
}

::-moz-scrollbar-thumb {
  border-radius: 3px;
  background-color: #aaaaaa;
}

::-moz-scrollbar-track {
  background: #666666;
}

/*safari trackbar css*/
::-ms-scrollbar {
  width: 7px;
  height: 7px;
}

::-ms-scrollbar-thumb {
  border-radius: 3px;
  background-color: #aaaaaa;
}

::-ms-scrollbar-track {
  background: #666666;
}
p {
  font-size: 12px;
  font-family: 'roboto';
  color: $text-color;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
h1 {
  font-size: 24px !important;
  font-weight: 500 !important;
 color: $text-color;
  font-family: 'Roboto' !important;
  margin: 0px !important;
  padding:0px !important;
}
h2 {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: $text-color;
  font-family: 'Roboto' !important;
  margin: 0px !important;
  padding:0px !important;
}
h3 {
  font-size: 24px !important;
  font-weight: 500 !important;
  color: $text-color;
  font-family: 'Roboto' !important;
  margin: 0px !important;
  padding:0px !important;
}
h5 {
  font-size: 16px !important;
  font-weight: bold !important;
  color: $text-color;
  font-family: 'Roboto' !important;
  margin: 0px !important;
  padding:0px !important;
}

h6 {
  font-size: 12px !important;
  color: $text-color;
  font-family: 'Roboto' !important;
  margin: 0px !important;
  padding:0px !important;
}
h4{
  font-size: 14px !important;
  font-weight: bold !important;
  color: $text-color;
  font-family: 'Roboto' !important;

}
a {
  text-decoration: none;
}
.theme-btn {
  background: $btn-bg;
  width: 120px;
  height: 32px;
  color: $white-text;
}
.t-flex {
  display: flex;
}
.d__grid {
  display: grid;
}
.w-100 {
  width: 100%;
}
.w-60px {
  width: 60px;
}
.w-100px {
  width: 100px;
}
.w-200px {
  width: 200px;
}
.w-110px {
  width: 110px;
}
.w-120px {
  width: 120px;
}
.w-130px {
  width: 130px;
}
.w-140px {
  width: 140px;
}
.w-150px {
  width: 150px;
}
.w-160px {
  width: 160px;
}
.w-300px {
  width: 300px;
}
.w-170px {
  width: 170px;
}
.w-180px {
  width: 180px;
}
.colgap-8 {
  column-gap: 8px;
}
.colgap-4 {
  column-gap: 4px;
}
.colgap-16 {
  column-gap: 16px;
}
.colgap-12{
  column-gap: 12px;
}
.colgap-24{
  column-gap: 24px;
}
.colgap-32{
  column-gap: 32px;
}
.rowgap-8 {
  row-gap: 8px;
}
.rowgap-16 {
  row-gap: 16px;
}
.rowgap-32 {
  row-gap: 36px;
}
.rowgap-12{
  row-gap: 12px;
}
.rowgap-4{
  row-gap: 4px;
}

.show_cursor {
  cursor: pointer;
}
.grey-bg {
  background: $grey-bg;
}
.grey-btn {
  background: var(--btn-bg3);
  color: var(--link-text2);
}
.org-btn {
  background: $btn-bg;
  color: $white-text;
}
.mt-2 {
  margin-top: .5rem;
}
.mb-2 {
  margin-bottom: .5rem;
}
.box-s {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
}
.mat-select-arrow {
  color:#ccc;
}
.mat-select-value {
  color: #ccc;
}
div#mySidenav {
  z-index: 99999 !important;
}
.page__title {
  h1 {
    color: var(--box-text);
  }
}
.close-icon {
  cursor: pointer;
}
.flex-c {
  display: flex;
  align-items: center;
}
.flex-column {
  display:flex;
  flex-direction: column;
}
.pdl-5{
  padding-left: 5px;
}
.pdl-8{
padding-left: 8px;
}
.pdt-8{
  padding-top: 8px;
  }
  .pdl-12{
    padding-left: 12px;
    }



/* mat-form-field cust css */
::ng-deep .cust-new-style {
 .mat-form-field-wrapper {
  padding-bottom: 0;

  .mat-form-field-flex {
    background: $theme-bg2;
    border-radius: 2px;
    padding: 0 !important;

    .mat-form-field-infix {
      .mat-input-element::placeholder {
        color: $text-color2;
        font-size: 13px;
      }
      .mat-icon {
        padding-left: 8px;
      }
      width: 192px;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      border-top: 0;
      padding-top: 0;
      // width: 160px;
      input {
        margin-top: 0;
        background: $grey-bg2 !important;
        height: 32px !important;
        padding-left: 8px;
        color: $link-text;
        border-radius: 2px;
      }

      .mat-form-field-label-wrapper {
        .mat-form-field-label {
          margin-bottom: 0 !important;
        }
      }
    }
    .mat-form-field-suffix {
      position: absolute;
      right: 0px;
      bottom: 0px;
      .mat-datepicker-toggle {
        color: $text-color2;
      }
    }
  }

  .mat-form-field-underline {
    display: none;
  }
}
}
::ng-deep {
  .mat-select-panel {
    background: var(--text-color3) !important;
    padding: 4px  0 !important;
    .mat-option {
      color: var(--link-text) !important;
      height: 32px !important;
    }
  }
   .mat-select-panel {
    background: var(--text-color3) !important;
    .mat-menu-item {
      color: var(--link-text) !important;
    }
    }
    .mat-menu-panel {
      background: var(--text-color3) !important;
      color: var(--link-text) !important;
    }
    .mat-menu-content {
      background: var(--text-color3) !important;
      .mat-menu-trigger {
        &::after {
          stroke: var(--link-text) !important;
        }
      }
      .mat-menu-item {
        color: var(--link-text) !important;
        &-highlighted:not([disabled]) {
          background: var(--grey-bg3) !important;
          color: var(--link-text) !important;
        }
      }
      .mat-menu-item:hover:not([disabled]) {
        background: var(--grey-bg3) !important;
      }
    }
    .mat-checkbox {
      &.mat-checkbox-disabled {
        .mat-checkbox-inner-container {
          .mat-checkbox-frame {
            background: var(--tat-btn-bg) !important;   
            box-shadow: none !important;    
            // .mat-checkbox-frame {
            //   border-color: $text-color !important;
            // } 
          }
            
        }
        .mat-checkbox-layout {
          .mat-checkbox-label {
            color: var(--disabled-key-color) !important;
          }
        }
      }
    }
    .p-person, .p-organizationchart .p-person .cust-form-group  {
      .mat-select[aria-expanded=true] .mat-select-arrow-wrapper {
        transform: unset !important;
        .mat-select-arrow {
          -webkit-transform-origin-y: unset !important;
        }
      }
    }
    .mat-select[aria-expanded=true] .mat-select-arrow-wrapper {
      transform: translateY(10%);

      .mat-select-arrow {
        display: flex!important;
        transform: rotate(225deg)!important;
      }
    }
}
 // temporary solution for svg
  html[data-theme*="light"]  {
    [class*="dd--block"] {
      .mat-menu-item {
        .sml__chk--icon {
          color: var(--link-text) !important;
        }
      }
      .mat-menu-trigger:after {
        background-image: url("./assets/images/menu-arrow-light.svg");
      }
    }
 }
  // end here

/* mat-form-field cust css end*/
.flex-sap-bw {
  display: flex;
  justify-content: space-between;
}
.flex-sap-around{
  display: flex;
  justify-content:space-around;
}
.highlight{
  background-color: var(--highlight-color) !important;
  color:#2d2d2d;
  font-family: inherit;
}
.highlight.selected-highlight{
  background-color: #faa68b !important;
  color:#2d2d2d;
}
.selected-permission{
  background-color: var(--selected-bg) !important;
  color: #444 !important;
  font-weight: bold !important;
}
.flex-jc{
  display: flex;
  justify-content: center;
  align-items: center;
}
.cur-point{
  cursor: pointer;
}
// For Light theme
html[data-theme*="light"]  {
  [class*="dd--block"] {
    .mat-menu-item {
      .sml__chk--icon {
        color: var(--link-text) !important;
      }
    }
    .mat-menu-trigger[aria-disabled="true"] {      
      &::after {
        background-image: url("./assets/images/arrow-right-disabled-w.svg") !important; 
      }
    }
    .mat-menu-trigger:after {
      background-image: url("./assets/images/menu-arrow-light.svg");
    }
  }
  .sml__chk--icon2 {    
    &::before {
      content: '';
      background: url(./assets/images/chk-icon--black.svg) !important;
    }
  }
  .cust__chk, .cust__chk1 {
    &.mat-checkbox-disabled {
      .mat-checkbox-inner-container {      
        .mat-checkbox-frame {
          box-shadow: none !important;
        }
      }
    }
    .mat-checkbox-inner-container {      
      .mat-checkbox-frame {
        box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.5) !important;
      }
    }
  }
}
// end here


//mat-slide-toggle css

::ng-deep {
html[data-theme*="light"] {
  .mat-slide-toggle {
    .mat-slide-toggle-bar {
      box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.5) !important;
      .mat-slide-toggle-thumb-container {
        .mat-slide-toggle-thumb {
          box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5),
            0 0 1px 0 rgba(0, 0, 0, 0.5) !important;
        }
      }
    }
    &.mat-checked {
      .mat-slide-toggle-bar {
        .mat-slide-toggle-thumb-container {
          .mat-slide-toggle-thumb {
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5),
              0 0 1px 0 rgba(0, 0, 0, 0.5) !important;
          }
        }
      }
    }
  }
}

.mat-slide-toggle {
  vertical-align: middle;
  .mat-slide-toggle-bar {
    background-color: var(--grey-text) !important;
    width: 32px;
    height: 20px;
    border-radius: 16px;
    box-shadow: inset 0 0 3px 0 #000;
    .mat-slide-toggle-thumb-container {
      position: absolute;
      z-index: 1;
      width: 14px;
      height: 14px;
      top: 50%;
      left: 0%;
      transform: translate(0%, -50%) !important;
      border-radius: 10px;
      //box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5), 0 0 1px 0 rgba(0, 0, 0, 0.5);
      background-color: $white-text;
      left: 3px;
      .mat-slide-toggle-thumb {
        width: 14px;
        height: 14px;
        background: $white-text;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5), 0 0 1px 0 rgba(0, 0, 0, 0.5);
      }
    }
    .mat-ripple-element {
      background: var(--grey-text);
    }
  }
  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: #faa68b !important;
      .mat-slide-toggle-thumb-container {
        top: 50%;
        left: 50%;
        transform: translate(0%, -50%) !important;
        background-color: $white-text;
        .mat-slide-toggle-thumb {
          width: 14px;
          height: 14px;
          background: $white-text;
        }
      }
    }
    .mat-ripple-element {
      background: $selected-bg;
    }
    .mat-slide-toggle-label {
      color: var(--toggle-active-label) !important;
    }
  }
  .mat-slide-toggle-label {
    color: var(--placeholder-color);
  }
}
// error border css starts
// .mat-form-field-invalid {
//   .mat-form-field-infix input {
//     border: inset 1px var(--validation-color)!important;
//   }
// }
// .mat-input-element.ng-invalid {
//   border: inset 1px var(--validation-color)!important;
// }
// #player-reference-form {
//   .mat-form-field-invalid  .mat-form-field-flex {
//     border: inset 1px var(--validation-color)!important;
//   } 
// }  
 
// #player-reference-form {
//   .mat-input-element.ng-invalid {
//     border: unset !important;    
//   }
// }
// error border css ends
}
::ng-deep {
  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: var(--selected-bg) !important;
  }
}
// end here
.sticky{
  position:sticky;
}
.custom-snackbar{
  background-color: #faa68b;
  color: #444!important;
}
::ng-deep .cust-field {
  .mat-form-field-wrapper {
    padding-bottom: 0;

    .mat-form-field-flex {
      background: $theme-bg2;
      border-radius: 2px;
      padding: 0 !important;

      .mat-form-field-infix {
        display: flex;
        //flex-direction: column;
        padding-bottom: 0;
        width: 240px;
        input {
          background: $grey-bg2 !important;
          height: 32px !important;
          padding-left: 8px;
          color: $link-text;
          border-radius: 2px;
        }

        .mat-form-field-label-wrapper {
          .mat-form-field-label {
            margin-bottom: 0 !important;
          }
        }
      }
      .mat-form-field-suffix {
        position: absolute;
        right: 0px;
        bottom: 0px;
        .mat-datepicker-toggle {
          color: $text-color2;
        }
      }
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}
::placeholder {
  color: var(--text-color6) !important;
  font-size: 12px !important;
}
::ng-deep {
  .snackbar {
    display: flex !important;
    align-items: center;
    &.mat-snack-bar-container {
      min-width: 216px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5), 0 0 2px 0 rgba(0, 0, 0, 0.5) !important;
      padding: 14px !important;
      margin: 0 20px 20px 0;
      background: #eeeeee;
      p {
        margin-bottom: 0 !important;
      }

      & * {
        font-size: 13px !important;
        color: #505050;
      }
    }

  }
  .custTooltip {
    background-color: var(--custom-tooltip-bg);
    color: var(--custom-tooltip-color);      
    line-height: 2.18;
    font-size: 11px;
    padding: 0 4px !important;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 0.5px #f37b54;
    // white-space: nowrap !important;
    // width: fit-content !important;
    max-width: -webkit-fill-available !important;
    // max-height: 250px;
    overflow: auto !important;
    overflow-wrap: anywhere !important;
    width: fit-content !important;
  }
}
.center-text{
  text-align: center;
}
::ng-deep {
  .ngx-org {
  &-box {
      flex-direction: column !important;
  }
}
.ngx-org-box {
  border: none !important;
  box-shadow: none !important;
}
div[class*="ngx-org-box"] {
  border: none !important;
  box-shadow: none !important;
}
div[class*="ngx-org-image"] {
  border: none !important;
}
}
// .enterprise_user--sidebar {
//   position: relative;
//   #main {
//     position: absolute;
//   }
// }
// .main__container {
//   margin-left: 40px;
// }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    box-shadow: 0 0 0 30px  var(--input-field-bg) inset !important;
    -webkit-box-shadow: 0 0 0 30px  var(--input-field-bg) inset !important;
    -webkit-text-fill-color: var(--link-text)!important;
    transition: background-color 5000s ease-in-out 0s;
}
::ng-deep {
  .custom-backdrop-overlay {
    opacity: 0.8 !important;
    background-color: var(--backdrop-bg);
  }
  .custom-backdrop-overlay-no-blur {
    opacity: 0 !important;
  }
  #main {    
    height: -webkit-fill-available !important;    
    position: fixed;
    z-index: 99;
  }
  [class*="main__container"] {
    margin-left: 40px;
    .main__container {
      margin-left: 0 !important;
      .container__files {
        padding-left: 0 !important;
        padding-top: 0px !important;
      }
    }
  }
}
  @media (max-width: 1280px) {
    div#main {
      row-gap: 8px !important;
      z-index: 999;
      a:nth-child(2) {
        margin-top: 28px !important;
      }
    } 
  }
  .mat-input-element {
    caret-color: white;
}
// mat-snackbar panel class
.mat-snack-bar-container {
  color: #000;
  width: 660px;
  height: 52px;
  margin: 8px 121px 20px 48px;
  padding: 10px 12px !important;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5), 0 0 2px 0 rgba(0, 0, 0, 0.5);
  background-color: #eee;
  max-width: 45vw !important;
}
.mat-select-placeholder {
  color:var(--link-text);
  // box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.5);
}

.mat-dialog-container {
  background: var(--theme-bg);
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 50%), 0 2px 4px 0 rgb(0 0 0 / 50%);
}

.player {
  .center {
    p {
      span{
        strong {
          font-family: var(--font-family) !important;
        }
        i {
          font-family: var(--font-family) !important;
        }
      }
    }
  }
}
.exapn-icon{
.mat-expansion-panel-header-description, .mat-expansion-indicator::after{
  color: #f4f3f0 !important;
}
}
.help-img{
  width: 16px;
  height: auto;
  vertical-align: middle;
}
::ng-deep {
  @media (max-width:1024px)
  {
      body
      {
          display: none;
      }
      html {
        
        &::after {
          position: absolute;          
          content: "Application doesn’t Support for TAB & MOBILE devices";
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          color: #979797;
          font-size: 20px;
          /* font-weight: 600; */
          letter-spacing: 0.3px;
          text-align: center;
          line-height: 20px;
          font-family: "Roboto";    
        }
      }
      html {
          background: #3a3a3a;
          position: relative;
          height: 100%;
          &::before {
            content: "";
            background: url('./assets/images/tab-and-mobile.svg') no-repeat 50% 50%;
            position: absolute;
            top: 50%;
            text-align: center;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -70%);
          }
      }
  }
  .time-zone {
    margin-left: 2px !important;
  }
}