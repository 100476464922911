@import "../variables.scss";

@mixin mat-input-feild-scss {
    #player-reference-form {
        .mat-form-field-appearance-outline .mat-form-field-outline-start,
        .mat-form-field-appearance-outline .mat-form-field-outline-end {
            border: 0 !important;
        }
        min-height: 120px;
        .mat-form-field-appearance-outline .mat-form-field-wrapper {
            margin: 0px;
            padding: 0px;
            background-color: var(--input-field-bg);
            border-radius: 2px;
            color: var(--link-text);  
            height: 32px;
            .mat-form-field-flex {
                height: 32px;         
                margin-top: 0;                
                border-radius: 2px;       
                box-shadow: inset 0 0 1px 0 rgb(0 0 0 / 50%);
                .mat-form-field-infix {
                    padding: 0em 0 0em 0;
                    color: var(--link-text);
                    .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
                        color: var(--link-text) !important;
                    }
                }
            }          
        }

        // standard
        .mat-form-field {
            height: 32px;
            .mat-input-element {
                color: var(--link-text);
                caret-color:var(--link-text);;
            }
            .mat-form-field-label {
                color: slategray;
            }
            .mat-form-field-underline {
                background-color: slategray;
            }
            .mat-form-field-ripple {
                background-color: slategray;
            }
            .mat-form-field-required-marker {
                color: slategray;
            }
        }

        // focused
        .mat-form-field.mat-focused {
            .mat-form-field-label {
                color: #ffffff;
            }
            // .mat-form-field-ripple {
            //     background-color: #ff884d;
            // }
            .mat-form-field-required-marker {
                color: #ffffff;
            }
            .mat-input-element {
                color: #ffffff;
            }
        }

        // invalid
        .mat-form-field.mat-form-field-invalid {
            .mat-input-element {
                color: var(--link-text);
                caret-color: var(--link-text);
            }
            .mat-form-field-label {
                color: #ffffff;
                .mat-form-field-required-marker {
                    color: #ffffff;
                }
            }
            // .mat-form-field-ripple {
            //     background-color: #ff33cc;
            // }
        }

        .mat-error {
            color: var(--validation-color) !important;
            padding-top: 15px;
            font-size: 13px;
        }

        .mat-form-field-appearance-outline .mat-form-field-prefix,
        .mat-form-field-appearance-outline .mat-form-field-suffix {
             top: 0em; 
        }
    }
}
